<template>
  <div class="form-property-price-list">
    <bg-skeleton
      v-if="loadingFetchData"
      width="40%"
      :height="40"
      data-testid="form-property-price-list-loader"
    />
    <template v-else>
      <div class="form-property-price-list__header-section">
        <bg-text size="heading-2">{{ xKosListingData.name }}</bg-text>
        <bg-text size="heading-4" class="form-property-price-list__subtitle"
          >Harga Sewa Kamar</bg-text
        >
        <bg-alert title="Informasi" :closable="false" v-if="hasDiscount">
          <ul>
            <li>
              Perubahan harga sewa di PMS dapat dilakukan untuk properti yang
              tidak mengaktifkan diskon/promo. Apabila terdapat diskon/promo
              yang aktif, ubah harga sewa melalui Bangkerupux.
            </li>
            <li>
              Rp0 menunjukkan bahwa kos tidak menyediakan harga sewa untuk waktu
              sewa tersebut.
            </li>
          </ul>
        </bg-alert>
      </div>
      <div class="form-property-price-list__form-section">
        <bg-grid class="form-property-price-list__form-row">
          <bg-grid-item :col="6">
            <input-currency-masking
              class="form-property-price-list__input"
              field-label="Harga Sewa Kamar / Hari"
              prefix="Rp"
              v-model="prices.daily.normal_price"
              :disabled="!!prices.daily.promo_price"
              max-char="12"
            />
            <input-currency-masking
              class="form-property-price-list__input"
              field-label="Harga Sewa Kamar / Bulan"
              prefix="Rp"
              v-model="prices.monthly.normal_price"
              :disabled="!!prices.monthly.promo_price"
              max-char="12"
            />
            <input-currency-masking
              class="form-property-price-list__input"
              field-label="Harga Sewa Kamar / 6 Bulan"
              prefix="Rp"
              v-model="prices.semiannually.normal_price"
              :disabled="!!prices.semiannually.promo_price"
              max-char="12"
            />
          </bg-grid-item>
          <bg-grid-item :col="6">
            <input-currency-masking
              class="form-property-price-list__input"
              field-label="Harga Sewa Kamar / Minggu"
              prefix="Rp"
              v-model="prices.weekly.normal_price"
              :disabled="!!prices.weekly.promo_price"
              max-char="12"
            />
            <input-currency-masking
              class="form-property-price-list__input"
              field-label="Harga Sewa Kamar / 3 Bulan"
              prefix="Rp"
              v-model="prices.quarterly.normal_price"
              :disabled="!!prices.quarterly.promo_price"
              max-char="12"
            />
            <input-currency-masking
              class="form-property-price-list__input"
              field-label="Harga Sewa Kamar / 12 Bulan"
              prefix="Rp"
              v-model="prices.annually.normal_price"
              :disabled="!!prices.annually.promo_price"
              max-char="12"
            />
          </bg-grid-item>
        </bg-grid>
        <bg-button variant="primary" @click="handleShowConfirmModal"
          >Simpan</bg-button
        >
      </div>
    </template>
    <bg-modal
      v-model="isShowConfirmModal"
      title="Yakin menyimpan perubahan?"
      description="Perubahan harga sewa yang dilakukan akan diterapkan langsung ke iklan kos."
      data-testid="confirmation-modal"
    >
      <template #footer>
        <div class="form-property-price-list__modal-footer">
          <bg-button
            class="form-property-price-list__modal-footer-btn"
            variant="tertiary"
            @click="handleShowConfirmModal"
            >Batal</bg-button
          >
          <bg-button
            variant="primary"
            @click="handleSaveUpdate"
            :loading="loadingButton"
            >Simpan</bg-button
          >
        </div>
      </template>
    </bg-modal>
  </div>
</template>

<script>
import {
  BgText,
  BgGrid,
  BgGridItem,
  BgButton,
  BgSkeleton,
  BgAlert,
  BgModal,
} from 'bangul-vue';
import { mapMutations, mapState, mapActions } from 'vuex';
import { LOADING_STATE } from '@admin_store/modules/propertyDetail';
import InputCurrencyMasking from '@admin/components/molecules/InputCurrencyMasking';
import formsPropertyInformationApi from '@admin_endpoints/forms-property-information';

export default {
  name: 'FormPropertyPriceList',

  components: {
    BgText,
    BgGrid,
    BgGridItem,
    BgButton,
    BgSkeleton,
    BgAlert,
    BgModal,
    InputCurrencyMasking,
  },

  computed: {
    ...mapState('propertyDetail', ['xLoadingStateListing', 'xKosListingData']),
    propertyId() {
      return this.$route.params.propertyId;
    },
    loadingFetchData() {
      return this.xLoadingStateListing === LOADING_STATE.FETCHING;
    },
    listingId() {
      return this.$route.params.listingId;
    },
    hasDiscount() {
      const timeList = [
        'daily',
        'weekly',
        'annually',
        'monthly',
        'quarterly',
        'semianually',
      ];
      const promoArray = [];
      timeList.forEach(time => {
        if (this.prices[time]) {
          promoArray.push(
            Object.keys(this.prices[time]).includes('promo_price')
          );
        }
      });

      return promoArray.includes(true);
    },
  },

  created() {
    this.xConsumeKosListing({
      propertyId: this.propertyId,
      listingId: this.listingId,
    });
  },

  data() {
    return {
      isDiscount: false,
      isShowConfirmModal: false,
      prices: {},
      loadingButton: false,
    };
  },

  watch: {
    xLoadingStateListing: {
      immediate: true,
      handler(value) {
        if (value === LOADING_STATE.FETCH_DONE) {
          this.prices = {
            ...this.xKosListingData.prices,
          };
          this.handleBreadcrumb();
        }
      },
    },
  },

  methods: {
    ...mapMutations('breadcrumb', ['updateBreadcrumb']),
    ...mapActions('propertyDetail', ['xConsumeKosListing']),

    handleBreadcrumb() {
      this.updateBreadcrumb({
        index: 1,
        item: {
          name: this.xKosListingData.name,
          url: `/property-detail/${this.propertyId}/kos`,
        },
      });
    },

    handleShowConfirmModal() {
      this.loadingButton = false;
      this.isShowConfirmModal = !this.isShowConfirmModal;
    },

    handleSaveUpdate() {
      this.removeDotInNumber(this.prices);
      this.updateNormalRoomPrice();
    },

    async updateNormalRoomPrice() {
      this.loadingButton = true;
      let result = {};
      try {
        result = await formsPropertyInformationApi.putUpdateNormalRoomPrice({
          propertyId: this.propertyId,
          listingId: this.listingId,
          data: {
            price: {
              ...this.prices,
            },
          },
        });
      } catch (error) {
        result.hasError = true;
        console.error(error);
        this.$toast.show(error.response.data.error);
      }
      this.$toast.show('Perubahan berhasil disimpan');
      this.handleShowConfirmModal();
      this.$router.push({
        name: 'property-detail.kos',
      });
      this.loadingButton = false;

      return result;
    },

    removeDotInNumber(value) {
      Object.keys(value).forEach(key => {
        value[key] = value[key].normal_price.toString().replace(/\D/g, '');
      });
    },
  },
};
</script>

<style lang="scss" scoped src="./FormPropertyPriceList.scss"></style>
