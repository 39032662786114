<template>
  <div class="form-property-facility">
    <bg-skeleton
      v-if="loadingFetchData"
      width="40%"
      :height="40"
      data-testid="form-property-facility-loader"
    />
    <template v-else>
      <div class="form-property-facility__header-section">
        <bg-text size="heading-2">{{ xKosListingData.name }}</bg-text>
      </div>
      <div class="form-property-facility__form-section">
        <div class="form-property-facility__category">
          <bg-text
            size="heading-4"
            class="form-property-facility__category-title"
            >Fasilitas</bg-text
          >
          <bg-grid>
            <bg-grid-item :col="6">
              <bg-field
                label="Luas Kamar"
                :message="roomAreaMessage"
                :error="isRoomAreaError"
              >
                <bg-input
                  v-model="facilityData.room_area"
                  data-testid="room-area"
                />
              </bg-field>
              <bg-field label="Apakah harga sewa termasuk biaya listrik?">
                <bg-grid>
                  <bg-grid-item :col="5">
                    <div class="form-property-facility__option">
                      <bg-radio
                        name="included"
                        id="electricity-included"
                        label="Ya"
                        :custom-value="true"
                        v-model="facilityData.electricity.included"
                        data-testid="electricity-included"
                      />
                      <bg-radio
                        name="electricity_included"
                        id="electricity-not-included"
                        label="Tidak"
                        :custom-value="false"
                        v-model="facilityData.electricity.included"
                        data-testid="electricity-not-included"
                      />
                    </div>
                  </bg-grid-item>
                </bg-grid>
              </bg-field>
            </bg-grid-item>
          </bg-grid>
          <bg-grid>
            <bg-grid-item :col="6">
              <bg-field label="Jenis Listrik">
                <bg-select
                  v-model="facilityData.electricity.type"
                  :options="electricityTypeOptions"
                  data-testid="electricity-type"
                />
              </bg-field>
            </bg-grid-item>
            <bg-grid-item :col="6">
              <bg-field label="Daya Listrik" message="Satuan: Watt">
                <bg-select
                  v-model="facilityData.electricity.power"
                  :options="electricityPowerOptions"
                  data-testid="electricity-power"
                />
              </bg-field>
            </bg-grid-item>
          </bg-grid>
        </div>

        <div class="form-property-facility__category">
          <bg-text
            size="heading-4"
            class="form-property-facility__category-title"
            >Fasilitas Kamar</bg-text
          >
          <multiple-input
            v-if="!loadingFetchTagList"
            :suggestions="facilityTags"
            :value="xKosListingData.room_facilities"
            data-testid="facility-tags"
            @input="handleFacilityInput"
          />
        </div>

        <div class="form-property-facility__category">
          <bg-text
            size="heading-4"
            class="form-property-facility__category-title"
            >Fasilitas Kamar Mandi</bg-text
          >
          <multiple-input
            v-if="!loadingFetchTagList"
            :suggestions="bathroomFacilityTags"
            :value="xKosListingData.bathroom_facilities"
            data-testid="bathroom-facilities"
            @input="handleBathroomFacilityInput"
          />
        </div>

        <bg-button
          variant="primary"
          data-testid="save-btn"
          @click="handleShowConfirmModal"
          >Simpan</bg-button
        >
      </div>
    </template>
    <bg-modal
      v-model="isShowConfirmModal"
      title="Yakin menyimpan perubahan?"
      description="Perubahan fasilitas yang dilakukan akan diterapkan langsung ke iklan kos."
      data-testid="confirmation-modal"
    >
      <template #footer>
        <div class="form-property-facility__modal-footer">
          <bg-button
            class="form-property-facility__modal-footer-btn"
            variant="tertiary"
            @click="handleShowConfirmModal"
            >Batal</bg-button
          >
          <bg-button
            variant="primary"
            @click="handleSaveFacility"
            :loading="loadingButton"
            >Simpan</bg-button
          >
        </div>
      </template>
    </bg-modal>
  </div>
</template>

<script>
import {
  BgSkeleton,
  BgText,
  BgField,
  BgInput,
  BgGrid,
  BgGridItem,
  BgButton,
  BgRadio,
  BgModal,
  BgSelect,
} from 'bangul-vue';
import { mapMutations, mapState, mapActions } from 'vuex';
import { LOADING_STATE } from '@admin_store/modules/propertyDetail';
import formsPropertyInformationApi from '@admin_endpoints/forms-property-information';
import MultipleInput from '@admin_molecules/MultipleInput';

export default {
  name: 'FormPropertyFacility',

  components: {
    BgSkeleton,
    BgText,
    BgField,
    BgInput,
    BgGrid,
    BgGridItem,
    BgButton,
    BgRadio,
    MultipleInput,
    BgModal,
    BgSelect,
  },

  computed: {
    ...mapState('propertyDetail', ['xLoadingStateListing', 'xKosListingData']),
    loadingFetchData() {
      return this.xLoadingStateListing === LOADING_STATE.FETCHING;
    },
    propertyId() {
      return this.$route.params.propertyId;
    },
    listingId() {
      return this.$route.params.listingId;
    },
    facilityData() {
      return this.xKosListingData?.general_facilities;
    },
    bathroomFacilityData() {
      return this.xKosListingData?.bathroom_facilities;
    },
    roomFacilityData() {
      return this.xKosListingData?.room_facilities;
    },
    facilityTags() {
      const tagsList = this.facilitySuggestion?.map(item => ({
        ...item,
        isDisable: false,
        label: item.name,
      }));

      return tagsList;
    },
    bathroomFacilityTags() {
      const tagsList = this.bathroomFacilitySuggestion?.map(item => ({
        ...item,
        isDisable: false,
        label: item.name,
      }));

      return tagsList;
    },
  },

  created() {
    this.consumeTagList('bedroom');
    this.consumeTagList('bathroom');
    this.xConsumeKosListing({
      propertyId: this.propertyId,
      listingId: this.listingId,
    });
  },

  data() {
    return {
      loadingButton: false,
      facilityList: [],
      bathroomFacilityList: [],
      propertySendData: {},
      facilitySuggestion: [],
      bathroomFacilitySuggestion: [],
      loadingFetchTagList: false,
      isShowConfirmModal: false,
      roomAreaMessage: 'Contoh: 3x4',
      isRoomAreaError: false,
      electricityTypeOptions: [
        {
          val: 'Token',
          label: 'Token',
        },
        {
          val: 'Pasca-Bayar',
          label: 'Pasca-Bayar',
        },
      ],
      electricityPowerOptions: [
        {
          val: '450',
          label: '450',
        },
        {
          val: '900',
          label: '900',
        },
        {
          val: '1300',
          label: '1.300',
        },
        {
          val: '2200',
          label: '2.200',
        },
        {
          val: '3500',
          label: '3.500',
        },
        {
          val: '5500',
          label: '5.500',
        },
      ],
    };
  },

  watch: {
    xLoadingStateListing: {
      immediate: true,
      handler(value) {
        if (value === LOADING_STATE.FETCH_DONE) {
          this.handleFacilityInput(this.xKosListingData.room_facilities);
          this.handleBathroomFacilityInput(
            this.xKosListingData.bathroom_facilities
          );
          this.handleBreadcrumb();
        }
      },
    },
  },

  methods: {
    ...mapMutations('breadcrumb', ['updateBreadcrumb']),
    ...mapActions('propertyDetail', ['xConsumeKosListing']),

    handleFacilityInput(input) {
      const list = input.map(({ id }) => id);
      this.facilityList = [...new Set(list)];
    },
    handleBathroomFacilityInput(input) {
      const list = input.map(({ id }) => id);
      this.bathroomFacilityList = [...new Set(list)];
    },
    handleBreadcrumb() {
      this.updateBreadcrumb({
        index: 1,
        item: {
          name: this.xKosListingData.name,
          url: `/property-detail/${this.propertyId}/kos`,
        },
      });
    },

    handleShowConfirmModal() {
      if (!this.facilityData.room_area) {
        this.isRoomAreaError = true;
        this.roomAreaMessage = 'Masukkan ukuran luas kamar.';
      } else {
        this.loadingButton = false;
        this.isShowConfirmModal = !this.isShowConfirmModal;
      }
    },

    async fetchTagList(type) {
      let result = {};
      try {
        result = await formsPropertyInformationApi.getPropertyTagList(type);
      } catch (error) {
        result.hasError = true;
        this.toast.message = result;
        this.toast.show = true;
        console.error(error);
      }
      return result;
    },

    handleSaveFacility() {
      this.propertySendData = {
        general_facilities: {
          room_area: this.facilityData.room_area,
          electricity_included: this.facilityData.electricity.included,
          electricity_type: this.facilityData.electricity.type,
          electricity_power: this.facilityData.electricity.power,
        },
        room_facilities: this.facilityList,
        bathroom_facilities: this.bathroomFacilityList,
      };
      this.updatePropertyFacility();
    },

    async consumeTagList(type) {
      this.loadingFetchTagList = true;
      const response = await this.fetchTagList(type);

      if (!response.hasError) {
        if (type === 'bedroom') {
          this.facilitySuggestion = response?.data?.tags;
        } else if (type === 'bathroom') {
          this.bathroomFacilitySuggestion = response?.data?.tags;
        }

        this.loadingFetchTagList = false;
        return response?.data?.tags;
      }
    },

    async updatePropertyFacility() {
      this.loadingButton = true;
      let result = {};
      try {
        result = await formsPropertyInformationApi.updatePropertyFacility({
          propertyId: this.propertyId,
          listingId: this.listingId,
          data: this.propertySendData,
        });
        this.$toast.show('Perubahan berhasil disimpan');
        this.handleShowConfirmModal();
        window.location.reload();
      } catch (error) {
        result.hasError = true;
        console.error(error);
        this.$toast.show(error.response.data.error);
      }
      this.loadingButton = false;
      return result;
    },
  },
};
</script>

<style lang="scss" scoped src="./FormPropertyFacility.scss"></style>
